import styled from 'styled-components';

import { Text } from '@vette/ui-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: url('/images/boarding.svg') no-repeat center center fixed;
  background-size: cover;
`;

export const Title = styled(Text)`
  font-weight: 500;
  font-size: 64px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.colors.white};
`;

export const Body = styled(Text)`
  text-align: center;
  margin: 0 24px;
  font-weight: 400;
`;
