import { Space } from 'antd';
import * as S from './styles';
type Props = {
  fitIn?: boolean;
};
export const NotFound: React.FC<Props> = () => (
  <S.Wrapper>
    <Space size={24}>
      <S.Title>404</S.Title>
      <img alt="NotFound" src="/images/eyes.svg" />
    </Space>
    <S.Body $size={18} $color="white">
      Sorry! We were unable to find what you were looking for.
    </S.Body>
  </S.Wrapper>
);
